const role = 'company';
const layout = 'company';

const company = [
  {
    path: '/company/projects',
    name: 'company.projects',
    component: () => import('@/views/company/projects/ProjectPublish.vue'),
    meta: {
      role,
      layout,
    },
  },
  {
    path: '/company/projects/:id',
    name: 'company.projects.show',
    component: () => import('@/views/company/projects/ProjectDetail.vue'),
    meta: {
      role,
      layout,
    },
  },
  {
    path: '/company/projects/:projectId/quotations/:quotationId',
    name: 'company.projects.quotations.show',
    component: () => import('@/views/company/quote/QuoteDetail.vue'),
    meta: {
      role,
      layout,
    },
  },
  {
    path: '/company/account',
    name: 'company.account',
    component: () => import('@/views/company/account/Account.vue'),
    meta: {
      role,
      layout,
    },
  },
]

export default company
