import { canNavigate } from "@/plugins/acl/routeProtection";
import Vue from "vue";
import VueRouter from "vue-router";
import company from "./company";
import worker from "./worker";

Vue.use(VueRouter);

const getDataStorage = () => {
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userRole = userData && userData.role ? userData.role : null;

  return { accessToken, userData, userRole };
};

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: "/",
    name: "home",
    redirect: (to) => {
      const { accessToken, userData, userRole } = getDataStorage();

      if (accessToken && userRole && userRole.name === "company")
        return { name: "company.projects" };
      if (accessToken && userRole && userRole.name === "worker")
        return { name: "worker.projects" };

      return { name: "auth-login", query: to.query };
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/Error404.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/register/professional",
    name: "auth-register-professional",
    component: () => import("@/views/RegisterProfessional.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/register/company",
    name: "auth-register-company",
    component: () => import("@/views/RegisterCompany.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot-password",
    component: () => import("@/views/ForgotPassword.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/change-password",
    name: "auth-change-password",
    component: () => import("@/views/ChangePassword.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/last-projects",
    name: "last-projects",
    component: () => import("@/views/public/LastProjects.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: false,
    },
  },
  ...company,
  ...worker,
  {
    path: "*",
    redirect: "error-404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (to.hash) {
          resolve({
            selector: to.hash,
            offset: { x: 0, y: 0 }, // avoid blocking the view when having fixed components
            behavior: "smooth",
          });
        }

        resolve({ x: 0, y: 0 });
      }, 500);
    });
  },
});

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const { accessToken, userRole } = getDataStorage();

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!accessToken)
      return next({
        name: "auth-login",
        query: { marketplace: to.query.marketplace },
      });

    //   // If logged in => not authorized
    //   return next({ name: 'misc-not-authorized' })

    //   // return next({ name: 'misc-not-authorized' })
  }

  // // Redirect if logged in
  if (accessToken && userRole && to.meta.role !== userRole.name) {
    next("/");
  }

  return next();
});

export default router;
