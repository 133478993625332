const role = 'worker';
const layout = 'worker';

const worker = [
  {
    path: '/worker/credits',
    name: 'worker.credits',
    component: () => import('@/views/worker/credits/CreditMain.vue'),
    meta: {
      role,
      layout,
    },
  },
  {
    path: '/worker/projects',
    name: 'worker.projects',
    component: () => import('@/views/worker/projects/ProjectPublish.vue'),
    meta: {
      role,
      layout,
    },
  },
  {
    path: '/worker/projects/:id',
    name: 'worker.projects.show',
    component: () => import('@/views/worker/projects/ProjectDetail.vue'),
    meta: {
      role,
      layout,
    },
  },
  {
    path: '/worker/quotations',
    name: 'worker.quotations',
    component: () => import('@/views/worker/quotations/QuotePublish.vue'),
    meta: {
      role,
      layout,
    },
  },
  {
    path: '/worker/quotations/:id',
    name: 'worker.quotations.show',
    component: () => import('@/views/worker/quotations/QuoteDetail.vue'),
    meta: {
      role,
      layout,
    },
  },
  {
    path: '/worker/account',
    name: 'worker.account',
    component: () => import('@/views/worker/account/Account.vue'),
    meta: {
      role,
      layout,
    },
  },
  {
    path: '/worker/checkout/success',
    name: 'worker.checkout.success',
    component: () => import('@/views/worker/checkout/Success.vue'),
    meta: {
      role,
      layout,
    },
  },
  {
    path: '/worker/checkout/error',
    name: 'worker.checkout.error',
    component: () => import('@/views/worker/checkout/Error.vue'),
    meta: {
      role,
      layout,
    },
  },
]

export default worker
